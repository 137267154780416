import React from 'react';
import CopyrightWrapper from './copyright.style';
// import Box from 'common/src/components/Box';
// import emailImage from 'common/src/assets/image/interior/email.svg';
// import Image from 'common/src/components/Image';

import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { envelope } from 'react-icons-kit/fa/envelope';
import { twitter } from 'react-icons-kit/fa/twitter';
import { youtubePlay as youtube } from 'react-icons-kit/fa/youtubePlay';
import { SocialList } from './copyright.style';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={envelope} />,
    name: 'envelope',
    link: 'mailto:li@feelsit.com',
  },
  {
    id: 2,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: 'https://www.facebook.com/Feelsit-Inc-106421001446921',
  },
  {
    id: 3,
    icon: <Icon icon={instagram} />,
    name: 'instagram',
    link: 'https://www.instagram.com/thefeelsit',
  },
  {
    id: 4,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: 'https://twitter.com/thefeelsit',
  },
  {
    id: 5,
    icon: <Icon icon={youtube} />,
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCIs5hTi9fzU-HMTOBH4lsfw',
  },
];

const Copyright = ({ data }) => {
  return (
    <CopyrightWrapper className="copyright_section">
      <SocialList>
        {socialLinks.map((item) => (
          <li className={item.name} key={`link-key${item.id}`}>
            <a aria-label={item.name} href={item.link}>
              {item.icon}
            </a>
          </li>
        ))}
      </SocialList>

      <p className="testimonialDes">
        Built with{' '}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 11.243 10.378"
        >
          <path
            id="f141"
            d="M10.324-44.135a3.191,3.191,0,0,1,.919,2.311,3.191,3.191,0,0,1-.919,2.311l-4.7,4.892-4.7-4.892A3.191,3.191,0,0,1,0-41.825a3.191,3.191,0,0,1,.919-2.311A2.618,2.618,0,0,1,2.905-45a2.618,2.618,0,0,1,1.986.865l.73.757.757-.757A2.6,2.6,0,0,1,8.351-45,2.6,2.6,0,0,1,10.324-44.135Zm0,0"
            transform="translate(0 45)"
            fill="#f4291e"
          />
        </svg>{' '}
        in San Jose, California.
      </p>
    </CopyrightWrapper>
  );
};

export default Copyright;
