import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
// import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Input from 'common/src/components/Input';
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline';
import Button from 'common/src/components/Button';
import { createUser } from '../../../graphql/mutations';
import { getUser } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { CircleLoader } from '../interior.style';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import CoverImage from 'common/src/assets/image/interior/cover.jpg';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share'

import { Icon } from 'react-icons-kit';
import BannerWrapper, {
  Container,
  ContentArea,
  HighlightedText,
  FormWrapper,
  ButtonGroup,
  CarouselArea,
  SelectWrapper,
} from './banner.style';

// import bannerImg from 'common/src/assets/image/interior/chair.png';
import ReactPlayer from 'react-player/youtube';

const shareUrl = 'https://www.feelsit.com';
const videoURL = 'https://youtu.be/ZPQF81pGwMs';

const ResponsivePlayer = () => (
  <div className="player-wrapper">
    <ReactPlayer
      className="react-player"
      url={videoURL}
      width="100%"
      height="100%"
      light={CoverImage}
      controls={true}
      config={{ youtube: { playerVars: { showinfo: 1 } } }}
    />
  </div>
);

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        bannerData {
          discount
          discountLabel
          title
          text
        }
      }
    }
  `);

  const { discount, discountLabel, title, text } = data.interiorJson.bannerData;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setLoading(true);
  }, []);

  const [state, setState] = useState({ email: '', valid: '' });
  const [showAdditionalInfo, setShowAdditionalInfo] = React.useState(false);
  const [isRegisterOK, setIsRegisterOK] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false)

  const [info, setInfo] = useState({
    name: '',
    country: 'United States',
    state: '',
    zip: '',
    address: '',
  });

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line

  const handleOnEmailChange = (e) => {
    let txt = e.target.value;
    if (txt.match(emailRegex)) {
      if (txt.length > 0) {
        setState({ ...state, email: txt, valid: 'valid' });
      }
    } else {
      if (txt.length > 0) {
        setState({ ...state, valid: 'invalid' });
      } else {
        setState({ ...state, valid: '' });
      }
    }
  };

  const handleOnNameChange = (name) => setInfo({ ...info, name });
  const handleOnAddressChange = (address) => setInfo({ ...info, address });
  const handleOnCityChange = (city) => setInfo({ ...info, city });
  const handleOnZipChange = (zip) => setInfo({ ...info, zip });

  const handleSubscriptionForm = async (e) => {
    e.preventDefault();
    if (!showAdditionalInfo) {
      if (state.email.match(emailRegex)) {
        const { email } = state

        const getResult = await API.graphql( graphqlOperation(getUser, {email}))

        if (getResult.data.getUser !== null ) {
          setIsAlreadyRegistered(true)
        } else {
          setIsAlreadyRegistered(false)

          setShowAdditionalInfo(true);
        }

      }
    } else {
      if (state.valid !== 'invalid') {
        setLoading(true);

        const { email } = state
        const input = { email, ...info };
        // const result = await API.graphql( graphqlOperation(createFeeluser, { input, condition: {not: {name: {eq: input.name}}} }) );
        const result = await API.graphql( graphqlOperation(createUser, { input }) );

        if (!result.errors) {
          setIsRegisterOK(true);

          const registerApi = await API.post('registerlambda', '/register', {
            body: input
          })

          // console.log('registerApi: ', registerApi)
        }
        

        setLoading(false);
      }
    }
  };

  const selectCountry = (val) => setInfo({ ...info, country: val });
  const selectState = (val) => setInfo({ ...info, state: val });


  return (
    <BannerWrapper id="introduction">
      <Container>
        <ContentArea>
          <Fade bottom delay={30}>
            <HighlightedText>
              <strong>{discount}</strong> {discountLabel}
            </HighlightedText>
            <Heading as="h1" content={title} />
            <Text content={text} />
            <p>
              <b>$100 off when you register.</b> Get this limited-time offer for
              FREE - no credit card needed, because we want to thank you for
              your early support!
            </p>

            {!isRegisterOK ? (
              <FormWrapper onSubmit={handleSubscriptionForm}>
                <Input
                  className={state.valid}
                  type="email"
                  placeholder="Enter email address"
                  icon={<Icon icon={iosEmailOutline} />}
                  iconPosition="left"
                  required={true}
                  onBlur={handleOnEmailChange}
                  aria-label="email"
                />
                {isAlreadyRegistered ? (
                  <Fade bottom delay={30}>
                    <p>
                      <b>
                        It seems that you've already registered. Thanks for re-visiting!
                      </b>
                    </p>
                  </Fade>
                ) : null}


                {showAdditionalInfo ? (
                  <Fade bottom delay={30}>
                    <div className="additional-info">
                      <Input
                        type="text"
                        label=""
                        className="input-field"
                        placeholder="Full Name"
                        onChange={handleOnNameChange}
                        required
                      />

                      <Input
                        type="text"
                        className="input-field"
                        label=""
                        placeholder="Address"
                        onChange={handleOnAddressChange}
                        required
                      />

                      <div className="two-col">
                        <Input
                          type="text"
                          className="input-field"
                          label=""
                          placeholder="City"
                          onChange={handleOnCityChange}
                          required
                        />
                        <Input
                          type="text"
                          className="input-field"
                          label=""
                          placeholder="Zip Code"
                          onChange={handleOnZipChange}
                          required
                        />
                      </div>

                      <SelectWrapper>
                        <CountryDropdown
                          value={info.country}
                          classes="select-css"
                          defaultOptionLabel="Select Country/Region"
                          onChange={(val) => selectCountry(val)}
                          required
                        />

                        <RegionDropdown
                          country={info.country}
                          value={info.state}
                          classes="select-css"
                          defaultOptionLabel="Select State/Province"
                          onChange={(val) => selectState(val)}
                          required
                        />
                      </SelectWrapper>
                    </div>
                  </Fade>
                ) : null}

                {loading ? (
                  <CircleLoader>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </CircleLoader>
                ) : null}

                <ButtonGroup>
                  <Button
                    type="submit"
                    colors="primaryWithBg"
                    title={showAdditionalInfo ? 'SUBMIT' : 'REGISTER'}
                    disabled={state.email !== '' && state.valid === 'invalid'}
                  />
                  <div className="ShareButton">
                    <EmailShareButton
                      url={shareUrl}
                      subject="Check out this immersive chair"
                      body={`Hey,

I like this idea and think you may be interested. Now it has $100 discount. 

Check out video: ${videoURL} and their website: `}
                      className="socialMediaButton">
                      <EmailIcon size={34} round/>
                    </EmailShareButton>
                    <FacebookShareButton 
                      url={shareUrl}
                      quote={"Feelsit - Built For Entertainment"}
                      hashtag="#feelsit"
                      className="socialMediaButton">
                      <FacebookIcon size={34} round/>
                    </FacebookShareButton>
                    <TwitterShareButton 
                      url={shareUrl}
                      title='I like this immersive chair! Now it has $100 discount. Check it out: '
                      hashtag="#feelsit"
                      className="socialMediaButton">
                      <TwitterIcon size={34} round/>
                    </TwitterShareButton>
                    <PinterestShareButton 
                      url={shareUrl}
                      media='https://i.ytimg.com/vi/CDLQCD25tLM/maxresdefault.jpg'
                      description="aa"
                      className="socialMediaButton">
                      <PinterestIcon size={34} round/>
                    </PinterestShareButton>
                    <RedditShareButton 
                      url={shareUrl}
                      title='I like this immersive chair! Now it has $100 discount. Check it out!'
                      className="socialMediaButton">
                      <RedditIcon size={34} round/>
                    </RedditShareButton>
                  </div>
                </ButtonGroup>
              </FormWrapper>
            ) : (
              <Fade bottom delay={30}>
                <p>
                  <b>
                    Thank you for registering, {info.name}! You'll receive an
                    email shortly with the confirmation.
                  </b>
                </p>
              </Fade>
            )}
          </Fade>
                
        </ContentArea>
        {/* End of content section */}

        <CarouselArea>
          <Fade in delay={100}>
            <ResponsivePlayer />
            {/* <Image src={bannerImg} alt="Banner" /> */}
          </Fade>
        </CarouselArea>
        {/* End of carousel section */}
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
