import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import error from 'common/src/assets/image/error.svg';
import success from 'common/src/assets/image/success.svg';

const shake = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const BannerWrapper = styled.div`
  padding-top: 30px;
  margin-bottom: 20px;
  background-color: ${themeGet('colors.banner', '#171717')};
  @media only screen and (max-width: 1440px) {
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 767px) {
    padding: 135px 0 0px;
    margin-bottom: 42px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1580px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    padding: 0 81px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0 60px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 50px;
  }
`;

export const ContentArea = styled.div`
  width: 595px;
  padding-right: 88px;
  @media only screen and (max-width: 1600px) {
    width: 560px;
  }
  @media only screen and (max-width: 1360px) {
    width: 40%;
  }
  @media only screen and (max-width: 1200px) {
    width: 45%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 480px) {
    padding-right: 0;
  }

  h1,
  p,
  .subtitle {
    color: ${themeGet('colors.label', '#C6C6C6')};
  }

  h1 {
    font-size: 48px;
    margin-bottom: 34px;
    + p {
      margin: 0;
    }
  }
`;

export const BannerImage = styled.img`
  flex-shrink: 0;
  width: calc(100% - 425px);
  img {
    margin-left: 80px;
    @media only screen and (max-width: 1600px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 480px) {
      max-width: 70%;
    }
  }
`;

export const HighlightedText = styled.p`
  display: flex;
  align-items: center;
  max-width: 270px;
  width: 100%;
  min-height: 28px;
  border-radius: 80px;
  padding: 3px 28px 3px 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${themeGet('colors.label', '#C6C6C6')};
  background-color: ${themeGet('colors.darkBg', '#0D0D0D')};
  margin: 0 0 40px;
  @media only screen and (max-width: 767px) {
    margin: 0 0 30px;
    padding: 3px 4px;
  }

  strong {
    display: inline-flex;
    align-items: center;
    min-width: 51px;
    min-height: 20px;
    padding: 3px 11px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    color: ${themeGet('colors.heading', '#191919')};
    background-color: ${themeGet('colors.primary', '#FDEF00')};
    margin-right: 10px;
  }
`;

export const SelectWrapper = styled.div`
  grid-column-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  .select-css {
    // display: block;
    font-size: 16px;
    font-family: sans-serif;
    // font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;

    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%;
  }
  /* Hide arrow icon in IE browsers */
  .select-css::-ms-expand {
    display: none;
  }
  /* Hover style */
  .select-css:hover {
    border-color: #888;
  }
  /* Focus style */
  .select-css:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  /* Set options to normal weight */
  .select-css option {
    font-weight: normal;
  }

  /* Support for rtl text, explicit support for Arabic and Hebrew */
  *[dir='rtl'] .select-css,
  :root:lang(ar) .select-css,
  :root:lang(iw) .select-css {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
  }

  /* Disabled styles */
  .select-css:disabled,
  .select-css[aria-disabled='true'] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  }

  .select-css:disabled:hover,
  .select-css[aria-disabled='true'] {
    border-color: #aaa;
  }
`;

export const FormWrapper = styled.form`
  margin-top: 45px;
  @media only screen and (max-width: 767px) {
    margin-top: 40px;
  }

  .additional-info {
    margin-top: 20px;
  }

  .two-col {
    grid-column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  .input-field {
    margin-bottom: 20px;
    label {
      color: ${themeGet('colors.textPrimary')};
      font-size: 16px;
      line-height: 1.33;
      font-weight: 400;
    }
  }
  input,
  select {
    border-radius: 5px;
    min-height: 60px;
    @media only screen and (max-width: 1024px) {
      min-height: 50px;
    }
  }

  .input_element {
    display: flex;
    align-items: center;
    position: relative;

    input {
      width: 100%;
      border: 0;
      font-size: 16px;
      padding: 20px 25px 20px 65px;
      border-radius: 5px;
      color: ${themeGet('colors.label', '#C6C6C6')};
      background-color: ${themeGet('colors.black', '#000000')};

      &::placeholder {
        color: ${themeGet('colors.lightText', '#7E7E7E')};
      }
    }

    .input-icon {
      position: absolute;
      left: 22px;

      i {
        color: ${themeGet('colors.lightText', '#7E7E7E')};
        svg {
          width: auto;
          height: 24px;
        }
      }
    }

    &::after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: calc(50% - 16px / 2);
      right: 25px;
    }

    &.invalid {
      &::after {
        background-image: url(${error});
      }
    }
    &.valid {
      &::after {
        background-image: url(${success});
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 50px;
  @media only screen and (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 54px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  .ShareButton {
    .socialMediaButton {
      &:hover > svg {
        height: 36px !important;
        width: 36px !important;
      }
    }
  }

  
  .reusecore__button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    &:first-child {
      margin-right: 20px;
      &:hover {
        opacity: 0.95;
      }
    }

    &:hover {
      .btn-icon {
        animation: ${shake} 1s infinite;
      }
    }
    &:disabled {
      color: graytext;
    }
  }

`;

export const CarouselArea = styled.div`
  width: calc(100% - 595px);
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  padding-top: 34%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  img,
  .player-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  @media only screen and (max-width: 1600px) {
    width: calc(100% - 560px);
    padding-top: 32%;
  }
  @media only screen and (max-width: 1360px) {
    width: 55%;
  }
  @media only screen and (max-width: 1200px) {
    width: 62%;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    width: 100%;
    padding-top: 56%;
  }

`;

export default BannerWrapper;
