import React, { Fragment, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { CircleLoader } from '../interior.style';
import BannerWrapper, {
  Container,
  ContentArea,
  HighlightedText,
  CarouselArea,
} from './aboutUs.style';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        aboutData {
          id
          subTitle
          title
          text
          text1
          carousel {
            id
            title
            thumb_url {
              publicURL
            }
            link
          }
        }
      }
    }
  `);

  const glideOptions = {
    type: 'carousel',
    perView: 1,
    // gap: 20,
    // breakpoints: {
    //   1200: {
    //     perView: 2,
    //   },
    //   667: {
    //     perView: 2,
    //   },
    //   480: {
    //     perView: 1,
    //   },
    // },
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <Fragment>
      {data.interiorJson.aboutData.map((section) => (
        <div id={section.id} key={section.id}>
          <BannerWrapper>
            <Container>
              <ContentArea>
                <Fade bottom delay={30}>
                  <HighlightedText>{section.subTitle}</HighlightedText>
                  <Heading as="h1" content={section.title} />
                  <Text content={section.text} mb="40px" />
                  <Text content={section.text1} mb="40px" />
                </Fade>
              </ContentArea>
              {/* End of content section */}

              <CarouselArea>
                {loading ? (
                  <GlideCarousel
                    carouselSelector={`carousel-${section.id}`}
                    options={glideOptions}
                    nextButton={<span className="next_arrow" />}
                    prevButton={<span className="prev_arrow" />}
                  >
                    <Fragment>
                      {section.carousel.map((item) => (
                        <GlideSlide
                          key={`carousel_key${section.id}-${item.id}`}
                        >
                          <a
                            href={item.thumb_url.publicURL}
                            className="item_wrapper"
                          >
                            <Image
                              src={item.thumb_url.publicURL}
                              alt={item.title}
                              className="carousel_img"
                            />
                            <Heading as="h4" content={item.title} />
                          </a>
                        </GlideSlide>
                      ))}
                    </Fragment>
                  </GlideCarousel>
                ) : (
                  <CircleLoader>
                    <div className="circle"></div>
                    <div className="circle"></div>
                  </CircleLoader>
                )}
              </CarouselArea>
              {/* End of carousel section */}
            </Container>
          </BannerWrapper>
        </div>
      ))}
    </Fragment>
  );
};

export default Banner;

// export default AboutUs;
