import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const CopyrightWrapper = styled.div`
  ul {
    display: flex;
    align-items: center;
    li {
      margin: 0 12px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #20201d;
      }
    }
    &:hover {
      li {
        a {
          &:not(:hover) {
            opacity: 0.4;
          }
        }
      }
    }
  }
  p {
    color: #20201d;
    font-size: 14px;
    margin: 30px 0 0;
  }
`;

export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 20px;

  li {
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
      margin-right: 20px;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: ${themeGet('colors.lightText', '#7E7E7E')};
      position: relative;
      i {
        position: relative;
        z-index: 1;

        svg {
          width: 18px;
          height: auto;
        }
      }

      &:hover {
        color: ${themeGet('colors.primary', '#FDEF00')};
      }
    }
  }
`;

export default CopyrightWrapper;
