import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { instagram } from 'react-icons-kit/fa/instagram';
import { envelope } from 'react-icons-kit/fa/envelope';
import { twitter } from 'react-icons-kit/fa/twitter';
import { youtubePlay as youtube } from 'react-icons-kit/fa/youtubePlay';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
// import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import AppImage from 'common/src/assets/image/interior/footerapp.svg';
import PlaystoreImage from 'common/src/assets/image/interior/footerplay.svg';

import FooterWrapper, {
  CurvIcon,
  List,
  ListItem,
  SocialList,
} from './footer.style';

const socialLinks = [
  {
    id: 1,
    icon: <Icon icon={envelope} />,
    name: 'envelope',
    link: 'mailto:li@feelsit.com',
  },
  {
    id: 2,
    icon: <Icon icon={facebook} />,
    name: 'facebook',
    link: 'https://www.facebook.com/Feelsit-Inc-106421001446921',
  },
  {
    id: 3,
    icon: <Icon icon={instagram} />,
    name: 'instagram',
    link: 'https://www.instagram.com/thefeelsit',
  },
  {
    id: 4,
    icon: <Icon icon={twitter} />,
    name: 'twitter',
    link: 'https://twitter.com/thefeelsit',
  },
  {
    id: 5,
    icon: <Icon icon={youtube} />,
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCIs5hTi9fzU-HMTOBH4lsfw',
  },
];

const Footer = ({ row, col, colOne, colTwo, titleStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      interiorJson {
        footerData {
          logo {
            publicURL
          }
          mail
          phone
          menuWidgets {
            id
            title
            menu {
              id
              text
              link
            }
          }
        }
      }
    }
  `);
  const { menuWidgets } = data.interiorJson.footerData;

  return (
    <Fragment>
      <CurvIcon></CurvIcon>

      <FooterWrapper id="contact">
        <Container width="1330px">
          
          <Box className="row" {...row}>
            <Box className="col-one" {...colOne}>
              <Heading
                className="appDownload"
                content="Download The App"
                {...titleStyle}
              />
              <Box className="imageWrapper">
                <a href="https://www.apple.com/ios/app-store/">
                  <Image src={AppImage} alt="App Image" />
                </a>
                <a href="https://play.google.com/store/apps">
                  <Image src={PlaystoreImage} alt="PlaystoreImage Image" />
                </a>
              </Box>
            </Box>
            {/* End of logo column */}

            <Box className="col-two" {...colTwo}>
              {menuWidgets.map((widget) => (
                <Box
                  className="col"
                  {...col}
                  key={`footer_widget-key${widget.id}`}
                >
                  <Heading
                    className="widget_title"
                    as="h3"
                    content={widget.title}
                  />
                  <List>
                    {widget.menu.map((item) => (
                      <ListItem key={`list__item-${item.id}`}>
                        <a href={item.link}>{item.text}</a>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}

              <Box className="col" {...col} key="99">
                <Heading className="widget_title" as="h3" content="CONTACT" />

                <SocialList>
                  {socialLinks.map((item) => (
                    <li className={item.name} key={`link-key${item.id}`}>
                      <a aria-label={item.name} href={item.link}>
                        {item.icon}
                      </a>
                    </li>
                  ))}
                </SocialList>
              </Box>
            </Box>
            {/* End of List column */}
          </Box>
          {/* End of widgets row */}
          <Box className="row copyright" {...row}>
            <Text className="text" content="@ Copyright 2021 Feelsit Inc." />
          </Box>
          {/* End of copyright row */}
        </Container>
      </FooterWrapper>
    </Fragment>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
};

export default Footer;
